<template>
  <v-hover>
    <v-list-item @click="goToPoi" v-ripple>
      <v-list-item-content>
        <v-list-item-title>{{ poi.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ poi.label }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon large>mdi-chevron-right</v-icon>
      </v-list-item-action>
    </v-list-item>
  </v-hover>
</template>

<script>
import format from 'date-fns/format';

export default {
  props: {
    poi: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    goToPoi() {
      const { id } = this.poi;
      this.$router.push({ name: 'poi', params: { id } });
    }
  },

  filters: {
    formatDate(date) {
      if (date) {
        return format(date, 'MMM d, yyyy');
      }
      return date;
    }
  }
};
</script>
