<template>
  <v-bottom-sheet v-model="showThis" inset>
    <v-card>
      <v-toolbar flat outlined>
        <v-toolbar-title>Filter POIs</v-toolbar-title>
        <v-spacer />
        <v-select label="Sort" prefix="Sort By" v-model="proxy.sortBy" :items="sortItems" prepend-icon="mdi-sort-variant" hide-details single-line />
        <template v-slot:extension>
          <v-tabs v-model="keyTab" show-arrows v-if="groupedItemKeys.length">
            <v-tab v-for="(ik, index) in groupedItemKeys" :key="index">
              <v-badge dot color="secondary" :value="hasActiveFilter(ik, 'groupKey')">{{ ik }}</v-badge>
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-row no-gutters>
        <v-col cols="2">
          <v-tabs v-model="categoryTab" vertical>
            <v-tab v-for="c in categories" :key="c">
              <v-badge dot color="secondary" :value="hasActiveFilter(c, 'category')">{{ c }}</v-badge>
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="10" class="filter-content">
          <v-tabs-items v-model="compositeTab">
            <v-tab-item v-for="(k, index) in groupedTagKeys" :key="`0-${index}`" :value="`0-${index}`">
              <v-chip-group v-model="proxy.tags" multiple column>
                <v-chip v-for="i in groupedTags[k]" :key="i" :value="i" filter outlined>{{ i }}</v-chip>
              </v-chip-group>
            </v-tab-item>
            <v-tab-item v-for="(k, index) in groupedLabelKeys" :key="`1-${index}`" :value="`1-${index}`">
              <v-chip-group v-model="proxy.labels" multiple column>
                <v-chip v-for="i in groupedLabels[k]" :key="i" :value="i" filter outlined>{{ i }}</v-chip>
              </v-chip-group>
            </v-tab-item>
            <v-tab-item v-for="(k, index) in groupedOrganizationKeys" :key="`2-${index}`" :value="`2-${index}`">
              <v-chip-group v-model="proxy.organizations" multiple column>
                <v-chip v-for="i in groupedOrganizations[k]" :key="i.id" :value="i.id" filter outlined>{{ i.name }}</v-chip>
              </v-chip-group>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn id="clear-filter" color="error" text large @click="resetFilter">Reset</v-btn>
        <v-btn id="apply-filter" color="primary" depressed large @click="applyFilter">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { clone, groupBy, isEmpty, uniq } from 'lodash';

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      categoryTab: 0,
      keyTab: 0,
      proxy: {
        tags: [],
        organizations: [],
        terms: [],
        labels: [],
        sortBy: 'name-desc'
      },
      sortItems: [{
        text: 'Created (Newest First)',
        value: 'created-desc'
      }, {
        text: 'Created (Oldest First)',
        value: 'created-asc'
      }, {
        text: 'Alphabetical (A-Z)',
        value: 'name-desc'
      }, {
        text: 'Alphabetical (Z-A)',
        value: 'name-asc'
      }]
    };
  },

  computed: {
    showThis: {
      get() {
        return this.show;
      },

      set(value) {
        this.$emit('update:show', value);
      }
    },

    categories() {
      const categories = ['tags', 'labels'];
      if (this.organizations.length > 1) {
        categories.push('organizations');
      }
      return categories;
    },

    groupedTags() {
      return groupBy(this.allPoiTags, i => i.charAt(0).toUpperCase());
    },

    groupedTagKeys() {
      return Object.keys(this.groupedTags).sort((a, b) => a.localeCompare(b));
    },

    groupedLabels() {
      return groupBy(this.allPoiLabels, i => i.charAt(0).toUpperCase());
    },

    groupedLabelKeys() {
      return Object.keys(this.groupedLabels).sort((a, b) => a.localeCompare(b));
    },

    groupedOrganizations() {
      return groupBy(this.organizations, i => i.name.charAt(0).toUpperCase());
    },

    groupedOrganizationKeys() {
      return Object.keys(this.groupedOrganizations).sort((a, b) => a.localeCompare(b));
    },

    groupedItemsKeys() {
      return [
        this.groupedTagKeys,
        this.groupedLabelKeys,
        this.groupedOrganizationKeys
      ];
    },

    groupedItemKeys() {
      return this.groupedItemsKeys[this.categoryTab] || [];
    },

    compositeTab() {
      return `${this.categoryTab}-${this.keyTab}`;
    },

    ...mapState(['organizations', 'filter']),
    ...mapGetters(['allPoiTags', 'allPoiLabels', 'isAdmin'])
  },

  watch: {
    filter: {
      immediate: true,
      handler() {
        this.proxy = clone(this.filter);
      }
    },

    categoryTab() {
      this.keyTab = 0;
    }
  },

  methods: {
    applyFilter() {
      const { labels, tags, organizations, terms, products = [], sortBy = 'name-desc', showArchived = false } = this.proxy;
      this.setFilter({
        labels,
        tags,
        organizations,
        terms,
        products,
        sortBy,
        showArchived
      });
      this.close();
    },

    resetFilter() {
      this.proxy = {
        labels: [],
        tags: [],
        organizations: [],
        terms: [],
        products: [],
        sortBy: 'name-desc',
        showArchived: false
      };
    },

    close() {
      this.$emit('update:show', false);
    },

    hasActiveFilter(key, keyType) {
      let hasActiveFilter = false;
      if (keyType === 'category') {
        hasActiveFilter = !isEmpty(this.proxy[key]);
      } else if (keyType === 'groupKey') {
        const category = this.categories[this.categoryTab];
        switch (category) {
          case 'organizations':
            hasActiveFilter = uniq(this.organizations.filter(o => this.proxy.organizations.includes(o.id)).map(i => i.name.charAt(0).toUpperCase())).includes(key);
            break;

          case 'labels': case 'tags':
            hasActiveFilter = uniq(this.proxy[category].map(i => i.charAt(0).toUpperCase())).includes(key);
            break;

          default:
            hasActiveFilter = false;
            break;
        }
      }
      return hasActiveFilter;
    },

    ...mapActions(['setFilter'])
  }
};
</script>

<style lang="scss">
.filter-content {
  max-height: 475px;
  overflow-y: auto;
}
</style>
